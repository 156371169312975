<template>
  <div>
    <Searchbar />
    <div class="flex">
      <SearchError v-if="zeroChannels" type="zeroChannels" />
      <SearchError v-if="deepStorage" type="zeroChannels" />

      <div v-if="!zeroChannels" class="w-full">
        <div class="base-bg-100 w-96 rounded ml-24 mt-20">

          <Tutorials />
          <ExampleSearchQueries />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Searchbar from '@/components/search/Searchbar'
import SearchError from '@/components/search/SearchError'
import Tutorials from '@/components/search/Tutorials.vue'
import ExampleSearchQueries from '@/components/search/ExampleSearchQueries.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Search',
  props: {
    items: Array,
  },
  components: {
    Searchbar, Tutorials, ExampleSearchQueries, SearchError
  },
  computed: {
    ...mapGetters({
      selectedBucket: 'general/selectedBucket'
    }),
    zeroChannels() {
      return this.selectedBucket.bucketId && this.selectedBucket.channels == 0
    },
    deepStorage(){
      return this.selectedBucket.bucketId && this.selectedBucket.deepStorage
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if(vm.mixin_route_guard(vm)){
        next()
      }else{
        next('/')
      }
    })
    
  }
}
</script>

<style scoped>
.scroller {
  height: calc(100vh - 6rem);
}
</style>